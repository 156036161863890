import { render, staticRenderFns } from "./VideoFairComment.vue?vue&type=template&id=3f437bde&scoped=true"
import script from "./VideoFairComment.vue?vue&type=script&lang=js"
export * from "./VideoFairComment.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f437bde",
  null
  
)

component.options.__file = "VideoFairComment.vue"
export default component.exports